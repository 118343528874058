import * as PropTypes from "prop-types"
import React from "react"
import { Link, graphql } from "gatsby"

import { QuestionAnswer } from "../../../components/index";
import { baseTokenType } from "../../../types";

class LegendaryTokensFaqPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { question, route } = props.data.faq.edges[0].node;
    this.backBreadcrumPages = [
      {
        text: 'FAQ',
        to: '/faq/'
      },
      {
        text: question,
        to: route
      }
    ];
  }
  getTokens(tokens) {
    return (
      <ol>
      {
        tokens.map((token)=>{
          const { 
            name, id, power, toughness
          } = token.node;
          const pt = power ? `${power}/${toughness} ` : '';
          return (
            <li key={id}>
              <Link
                to={`/token-list/${id}/`}
                state={{
                  breadcrum: this.backBreadcrumPages
                }}
              >
                {pt}{name}
              </Link>
            </li>
          )
        })
      }
      </ol>
    )
  }
  render() {
    const {
      data: {
        faq,
        legendaryTokens: {
          totalCount,
          edges
        },
        proxyTokens: {
          totalCount: proxyTotalCount,
          edges: proxyEdges
        }
      }
    } = this.props;
    const total = totalCount + proxyTotalCount;
    const intro = `There are a total of ${total} legendary Magic: the Gathering token cards. Of these only ${totalCount} have been officially printed by Wizards of the Coast, they are: `
    const proxy = `The ${proxyTotalCount} legendary tokens that have not yet been printed are: `
    const htmlAnswer = `${intro}<br>${this.getTokens(edges)}${proxy}<br>${this.getTokens(proxyEdges)}`;
    return (
      <QuestionAnswer {...faq.edges[0].node} answer={htmlAnswer}>
        <>
          <p>{intro}</p>
          {this.getTokens(edges)}
          <p>{proxy}</p>
          {this.getTokens(proxyEdges)}
        </>
      </QuestionAnswer>
    )
  }
}

LegendaryTokensFaqPage.propTypes = {
  data: PropTypes.shape({
    legendaryTokens: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseTokenType
        }),
      })).isRequired,
    }).isRequired,
    proxyTokens: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseTokenType
        }),
      })),
    }),
    faq: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            route: PropTypes.string.isRequired,
            question: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            formattedDate: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }).isRequired
}

export const legendaryFaqQuery = graphql`
  query LegendaryFaqQuery {
    legendaryTokens: allTypesJson(filter: {
      types: {
        eq: "Legendary"
      }
      proxy: {
        ne: true
      }
    }) {
      totalCount
      edges {
        node {
          name
          id
          power
          toughness
        }
      }
    }
    proxyTokens: allTypesJson(filter: {
      types: {
        eq: "Legendary"
      }
      proxy: {
        eq: true
      }
    }) {
      totalCount
      edges {
        node {
          name
          id
          power
          toughness
        }
      }
    }
    faq: allFaqJson(filter: {
      id: {
        eq: "faq5"
      }
    }) {
      edges {
        node {
          route
          question
          date
          formattedDate: date(formatString: "MMM Do, YYYY")
        }
      }
    }
  }
`
export default LegendaryTokensFaqPage